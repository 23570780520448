<template>
    <div>
        <Header />
        
            <img alt="Profile Pic" id="profile-image" src="../assets/shozzyoctocat_sml.png" />
        
        <div class="gift-list">
            <h3>Some Gift Card Options</h3>
            <p>The items ordered online might not arrive in time, so these gift cards might be the easiest option</p>
            <ul>
                <li>Amazon Gift Card</li>
                <li>JB Hi Fi Gift Card</li>
                <li>Coles Myer Gift Card</li>
                <li>Ikea Gift Card</li>
            </ul>
            
            <h3>Some non gift card options</h3>
            <p>If you'd rather not get a gift card, these would be great! Though I'm not sure about how long they might take to get here, I'm happy to wait for these to arrive after Christmas!</p>
            <p>Clicking on any of the items below takes you to a where you can buy it online.</p>
            
            <ul>
                <li><a href="https://www.amazon.com.au/gp/product/B08BZKB4K3?tag=elfau20-22">53mm Espresso Distributor and Tamper - Amazon Link</a></li>
                <li><a href="https://www.amazon.com.au/gp/product/B07VT533C3?tag=elfau20-22">Tamper mat - Amazon Link </a></li>
                <li><a href="https://www.amazon.com.au/dp/B082SWN84Q?tag=elfau20-22&linkCode=osi&th=1&psc=1">54mm Espresso Dosing Funnel - Amazon Link </a></li>
                <li><a href="https://www.bookdepository.com/99-Invisible-City-Roman-Mars/9781529355277">The  99% Invisible City - Bookdepository Link though you might be able to get this instore</a></li>
                <li><a href="https://www.uniqlo.com/au/store/men-dry-ex-short-sleeve-polo-shirt-4229620016.html">Uniqlo Dry-EX Short Sleeve Polo Shirt (Size: XS) Dark Gray</li>
                <li><a href="https://www.uniqlo.com/au/store/men-dry-ex-crew-neck-short-sleeve-t-shirt-4229640044.html">Uniqlo Dry-EX Crew Neck Short Sleeve T-Shirt (Size: XS) Black, Navy or Gray</li>
            </ul>
        </div>
    </div>
</template>

<script>
import Header from "../components/Header";

export default {
    components: {
        Header
    }
}
</script>

<style scoped>
 .gift-list{
     float:left;
 }

 a{
     color:white;
 }

 a:visited{
     color:white;
 }

 #profile-image {
    margin-top:10px;
    border-radius: 37px;
    background: #333333;
    /* Neumorphic shadow effect */
    box-shadow: 6px 6px 12px #272727, 
                -6px -6px 12px #3f3f3f;
    width:100px;
    height: 100px;
}
ul{
    text-align: left;
}
.centered{
    display:grid;
    place-items:center;
}
</style>